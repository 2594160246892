
export default {
  name: 'FooterSection',

  props: {
    json: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,
  }),

  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
  },

}
