
import { debounce } from 'debounce'
import search from '~/mixins/search'

export default {
  name: 'MegaMenuSearchNewLookResults',
  mixins: [
    search,
  ],

  props: {
    query: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      isBackspace: false,
    }
  },

  computed: {
    products () {
      return [...this.$search.products()].splice(0, 5)
    },

    productsGroup () {
      return this.products.reduce((acc, product) => {
        acc[product.mappedProductType] = acc[product.mappedProductType] || []
        acc[product.mappedProductType].push(product)
        return acc
      }, {})
    },

    categories () {
      return [...this.$search.category()].splice(0, 5)
    },

    articles () {
      return [...this.$search.articles()].splice(0, 5)
    },

    isSearchPage () {
      return /\/search\/q/.test(this.$route.path)
    },

    isScrolled () {
      return this.$css.breakpoints.smAndUp ? this.resultsItemsLength > 9 : this.resultsItemsLength > 7
    },
  },

  watch: {
    query (newItem) {
      if (!this.isBackspace && newItem) {
        this.pushGtm(newItem)
      }
    },
  },

  mounted () {
    window.addEventListener('keydown', this.onKeydown)
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeydown)
  },

  methods: {
    clickedOnProduct () {
      this.$emit('hide-results', 'clickedOnProductMobile')
    },

    boldSearchText (text, searchText) {
      return text === '' ? '' : text.replace(new RegExp(searchText, 'gi'), '<b><span style="color: var(--color-text-1);">$&</span></b>')
    },

    pushGtm: debounce(function (item) {
      this.$gtm.push({
        event: this.products.length ? 'search_main' : 'search_test',
        search_term: this.cleanedQuery,
      })
    }, 300),

    onKeydown (event) {
      this.isBackspace = event.key === 'Backspace'
    },
  },
}
