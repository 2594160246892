
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsHelloBar',
  mixins: [cmsBlock],

  data () {
    return {
      timer: null,
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: false,
      visible: true,
    }
  },

  // przykładowy json
  // {"textDate":"Pozostało","colorTextDate":"","endDateUTC":"", "coupon":"1", "header":"Tylko teraz zbadaj przyczynę nadwagi lub otyłości taniej o <strong>1000zł!</strong>","availablePages":["all"],"bgColor":"#29b1e7","fontColor":"#ffffff","fontSmallColor":"#ffffff","btnBgColor":"#ff507a","btnTextColor":"#ffffff","bgClose":"#ff507a","text":"Oferta obowiązuje dla członków <a href="https://www.medistore.com.pl/mediclub">MediClub.</a>","link":{"text":"Sprawdź","url":"/p/screening-otylosci", "mobileLookLink": "true"}}

  // określenie tylko niektórych stron
  // "availablePages":["/p/morfologia-krwi", "/c/badania/krwi", "/c/badania/usg", "/p/usg-jamy-brzusznej"]

  computed: {
    availablePages () {
      return !!this.blockContent?.availablePages?.find(page => page === 'all') || this.blockContent?.availablePages?.find(page => page === this.$route.path)
    },
  },

  mounted () {
    this.getTimer()
    this.timer = setInterval(this.getTimer, 1000)
  },

  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },

  methods: {
    updateRemaining (distance) {
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
    },

    getTimer () {
      const currentTime = new Date()
      const finished = new Date(`${this.blockContent?.endDateUTC}`)
      const distance = finished.getTime() - currentTime.getTime()

      this.updateRemaining(distance)

      if (distance <= 0) {
        clearInterval(this.timer)
        this.isEnded = true
      }
    },
  },

}
