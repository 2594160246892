
import { createNamespacedHelpers } from 'vuex'
import description from '~/config/descriptions'
import storeCode from '~/mixins/storeCode'
import modal from '~/mixins/modal'

const {
  mapState: mapStateConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  mixins: [
    storeCode,
    modal,
  ],

  head () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },

      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description[this.$config.storeCode]?.[this.$i18n.locale] || '',
        },
      ],

      script: this.isMedicoverAptekaStore
        ? [
          // DoDo
            {
              src: 'https://trustmate.io/api/widget/e58d3d07-3d92-4206-9b08-ef71eefb5c50/script',
              defer: true,
            },
          ]
        : [],
    }
  },

  computed: {
    ...mapStateConfig({
      hasNewLook: state => state.hasNewLook,
    }),

    isPreviewMode () {
      return this.$route.query.preview || false
    },

    isAsyncPage () {
      return /\/(c|p|customer)\//.test(this.$route.path)
    },
  },

  async mounted () {
    const workbox = await window.$workbox

    if (workbox) {
      workbox.addEventListener('installed', (event) => {
        if (event.isUpdate) {
          this.$nuxt.refresh()
        }
      })
    }
  },
}
