import getCategoryForMediclub from '~/graphql/mediclub/queries/getCategoryForMediclub.graphql'
import getCategoryProductsForMediclub from '~/graphql/mediclub/queries/getProductForMediclub.graphql'

export default async function mediclubProductLoader ({
  store,
  app,
}, blockName = '') {
  const blockData = store.getters['staticBlock/GET_BLOCKS'](blockName)
  const categoryPathKey = blockData?.[blockName]?.contentJson?.itemCategoryUrl

  // categoryPathKey = 'mediclub-oferty/styczen-2025'

  let limitedBenefitItems = []
  let activeIndex = 0

  if (!categoryPathKey) {
    return {
      limitedBenefitItems,
      activeIndex,
    }
  }

  const categoryFilters = { url_path: { eq: categoryPathKey } }
  const productFilters = { filter: { category_url_path: { eq: categoryPathKey } } }
  const fetchOption = { fetchOptions: { method: 'GET' } }

  const getCategoryPromise = app.$graphql({
    requestPayload: {
      query: getCategoryForMediclub,
      variables: { categoryFilters },
      context: fetchOption,
    },
  })

  const getCategoryProductsPromise = app.$graphql({
    requestPayload: {
      query: getCategoryProductsForMediclub,
      variables: productFilters,
      context: fetchOption,
    },
  })

  try {
    const [categoriesResult, productsResult] = await Promise.all([getCategoryPromise, getCategoryProductsPromise])

    const currentCategory = categoriesResult?.data?.currentCategory?.items[0]

    // czy mamy liste sortowania/aktywnych elementow
    const activeSlugs = blockData?.[blockName]?.contentJson?.activeSlugs || []

    if (activeSlugs.length) {
      currentCategory.children = currentCategory.children?.filter(({ urlKey }) => activeSlugs.includes(urlKey))
      activeIndex = 1
    }

    // lista kategorii jako chips
    limitedBenefitItems = currentCategory?.children?.map(child => ({
      uid: child.uid,
      key: child.name,
      blocks: productsResult.data.products.items
        .filter(item => item.categories.some(ic => ic.uid === child.uid))
        .map(item => ({
          title: item.name,
          img: item.image.url,
          text: item.shortDescription.html,
          to: app.localePath(`/p/${item.urlKey}`),
          priceRange: item.priceRange,
        })),
    })) || []
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  return {
    limitedBenefitItems,
    activeIndex,
  }
}
