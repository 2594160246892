
import description from '~/config/descriptions'
import storeCode from '~/mixins/storeCode'
import modal from '~/mixins/modal'

export default {
  mixins: [
    storeCode,
    modal,
  ],

  head () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },

      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description[this.$config.storeCode]?.[this.$i18n.locale] || '',
        },
      ],
    }
  },

  computed: {
    isPreviewMode () {
      return this.$route.query.preview || false
    },

    hasBreadcrumbs () {
      return !this.$route.path.includes('/search/q/') && this.$route.path !== '/' && this.$route.path !== '/en'
    },

    isBackgroundImageEnabled () {
      return /\/ac\b|\/ac\/|\/a\//.test(this.$route.path)
    },

    isCategoryPage () {
      return /\/c\//.test(this.$route.path)
    },

    isAsyncPage () {
      return /\/(c|p|customer)\//.test(this.$route.path)
    },
  },

  mounted () {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister()
        }
      })
    }
  },
}
