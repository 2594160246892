export default {
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORY_DISPLAY_MODE: 'GET_CATEGORY_DISPLAY_MODE',
  IS_GIFTCARD_ENABLED: 'IS_GIFTCARD_ENABLED',
  GET_CATEGORIES_IN_MENU: 'GET_CATEGORIES_IN_MENU',
  GET_MEGAMENU_NAVBAR_ITEMS: 'GET_MEGAMENU_NAVBAR_ITEMS',
  IS_MEGAMENU_AVAILABLE: 'IS_MEGAMENU_AVAILABLE',
  SET_FOOTER: 'SET_FOOTER',
  SET_STORECONFIG: 'SET_STORECONFIG',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_RECAPTCHA_MUTATIONS: 'SET_RECAPTCHA_MUTATIONS',
  SET_RECAPTCHA_QUERIES: 'SET_RECAPTCHA_QUERIES',
  SET_MEGAMENU_CATEGORIES: 'SET_MEGAMENU_CATEGORIES',
  SET_MEGAMENU_SNOWDOG_ID: 'SET_MEGAMENU_SNOWDOG_ID',
  SET_HAS_NEW_LOOK: 'SET_HAS_NEW_LOOK',
  SET_STORE_REVIEW: 'SET_STORE_REVIEW',
  SET_CATEGORY_DISPLAY_MODE: 'SET_CATEGORY_DISPLAY_MODE',
}
