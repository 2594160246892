export default () => {
  if (process.client) {
    const checkLC = setInterval(() => {
      if (window.LC_API) {
        clearInterval(checkLC)

        window.LC_API.on_chat_window_opened = function () {
          const widget = document.getElementById('chat-widget-container')

          if (widget) {
            widget.classList.add('chat-opened')

            // Usuwamy wymuszone style z body dodane przez widget czata
            document.body.style.position = ''
            document.body.style.height = ''
            document.body.style.width = ''
            document.body.style.inset = ''
            document.body.style.overflowY = ''
          }
        }

        window.LC_API.on_chat_window_minimized = function () {
          const widget = document.getElementById('chat-widget-container')

          if (widget) {
            widget.classList.remove('chat-opened')
          }
        }
      }
    }, 500)
  }
}
