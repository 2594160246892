import types from './types'

const mutations = {
  [types.SET_STORECONFIG] (state, storeConfig) {
    state.storeConfig = storeConfig
  },
  [types.SET_FOOTER] (state, footer) {
    state.footer = footer
  },
  [types.SET_CATEGORY] (state, category) {
    state.category = category
  },
  [types.SET_CATEGORY_DISPLAY_MODE] (state, categoryDisplayMode) {
    state.categoryDisplayMode = categoryDisplayMode
  },
  [types.SET_RECAPTCHA_MUTATIONS] (state, recaptchaMutations) {
    state.recaptchaMutations = recaptchaMutations
  },
  [types.SET_RECAPTCHA_QUERIES] (state, recaptchaQueries) {
    state.recaptchaQueries = recaptchaQueries
  },
  [types.SET_MEGAMENU_CATEGORIES] (state, items) {
    state.megaCategory = items
  },
  [types.SET_MEGAMENU_SNOWDOG_ID] (state, megaMenuId) {
    state.storeConfig.megaMenuId = megaMenuId
  },
  [types.SET_HAS_NEW_LOOK] (state, hasNewLook) {
    state.hasNewLook = hasNewLook
  },
  [types.SET_STORE_REVIEW] (state, storeReview) {
    if (storeReview) {
      state.storeReview = storeReview
    }
  },
}

export default mutations
