
export default () => {
  if (process.client) {
    let clsValue = 0
    let clsSources = []
    let lcpEntry = null

    // LCP
    const lcpObserver = new PerformanceObserver((entryList) => {
      for (const entry of entryList.getEntries()) {
        lcpEntry = entry // zawsze aktualizujemy LCP na najnowszy
      }
      console.log('Aktualny LCP:', lcpEntry)
    })

    // CLS
    const clsObserver = new PerformanceObserver((entryList) => {
      for (const entry of entryList.getEntries()) {
        if (!entry.hadRecentInput) {
          clsValue += entry.value
          if (entry.sources) {
            clsSources = clsSources.concat(entry.sources)
          }
        }
      }

      console.log('Bieżąca wartość CLS:', clsValue)

      //
      // console.log('Źródła CLS:', clsSources.map(src => ({
      //   node: src.node,
      //   previousRect: src.previousRect,
      //   currentRect: src.currentRect,
      // })))
    })

    lcpObserver.observe({ type: 'largest-contentful-paint', buffered: true })
    clsObserver.observe({ type: 'layout-shift', buffered: true })
  }
}
